<template>
  <div
    id="menu-bottom-menu"
    class="d-flex justify-content-around align-items-center menu-shadow"
  >
    <b-link
      v-ripple
      :to="{
        name: 'establishment-view',
        params: { id: establishment },
      }"
    >
      <feather-icon size="24" icon="HomeIcon" style="stroke-width: 3.3" />
    </b-link>
    <b-link v-ripple @click="$router.go(-1)">
      <i class="fas fa-chevron-left mx-1 custom-size"></i>
    </b-link>
    <b-link
      v-ripple
      :to="{
        name: 'establishment-sucursales',
        params: { id: establishment },
      }"
    >
      <i class="fas fa-store custom-size mr-2"></i>
    </b-link>
    <b-link
      v-ripple
      :to="{ name: 'establishment-facturas', params: { id: establishment } }"
    >
      <i class="fas fa-file-invoice-dollar custom-size"></i>
    </b-link>
    <b-link
      v-ripple
      :to="{ name: 'establishment-balances', params: { id: establishment } }"
    >
      <i class="fas fa-coins custom-size"></i>
    </b-link>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import { mapGetters } from "vuex"

export default {
  directives: {
    Ripple,
  },
  components: {
    BLink,
  },
  data() {
    return {
      establishment: localStorage.getItem("establishmentId"),
    }
  },
  computed: {
    ...mapGetters("pos", ["cartTotalProducts"]),
  },
}
</script>

<style lang="scss" scoped>
// Color palettes
@import "~@core/scss/base/core/colors/palette-variables.scss";

#menu-bottom-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 50px;
  box-shadow: 0 4px 24px 0 rgba(#000, 0.75);
  background-color: $white;
  z-index: 10;
}
.online-shopping-icon {
  $size: 55px;
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 24px 0 rgba(#000, 0.5);
  align-items: center;
  background: $success;
  margin-bottom: 2rem;
  width: $size;
  height: $size;
  border-radius: 2rem;
}
.shopping-cart-icon {
  $size: 55px;
  display: flex;
  justify-content: center;
  box-shadow: 0 4px 24px 0 rgba(#000, 0.5);
  align-items: center;
  background: $warning;
  margin-bottom: 2rem;
  width: $size;
  height: $size;
  border-radius: 2rem;
}

.custom-size {
  font-size: 1.6em; /* Ajusta el tamaño del ícono */
}
</style>
